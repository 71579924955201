<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div
					style="height: 70vh;overflow-y: auto;padding-right: 0.5rem;padding-bottom: 40px;box-sizing: border-box;">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
						<el-form-item label="履职报告名称" prop="name">
							<el-input v-model="ruleForm.name"></el-input>
						</el-form-item>
						<el-form-item label="履职报告正文" prop="content">
							<el-input type="textarea" :autosize="{ minRows: 14, maxRows: 14}"
								v-model="ruleForm.content"></el-input>
						</el-form-item>
						
						<el-form-item label="上传附件" class="fjbox" style="display: flex;align-items: flex-start;">
							<el-upload
							  class="upload-demo"
							  :action="baseurl+'/api/login/upload'" :on-success="handleAvatarSuccess2"
							  :on-remove="handleChange2"
							  :file-list="fileList">
							  <el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
						</el-form-item>
						
					</el-form>

				</div>

				<div style="display: flex;align-content: center;margin-top: 30px;">
					<el-button @click="backpage">取 消</el-button>
					<el-button type="warning" @click="submitForm(0)">暂 存</el-button>
					<el-button type="primary" @click="submitForm(1)">提 交</el-button>
				</div>
			</div>
		</div>

	</div>

</template>

<script>
	export default {
		data() {
			return {
				baseurl: "",
				ruleForm: {
					name: '',
					content: '',
					files:'',
					status:0
				},
				rules: {
					name: [{
						required: true,
						message: '请输入履职报告名称',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请填写履职报告正文',
						trigger: 'blur'
					}]
				},
				fileList:[],//附件
			}
		},
		created() {
			this.baseurl = this.$URL
			this.$post({
				url: '/api/user_resumption_report/details',
				params: {
					id:this.$route.query.id
				}
			}).then((res) => {
				this.ruleForm = JSON.parse(JSON.stringify(res))
				res.files.forEach((item,index)=>{
					let obj = {
						name:item.filename,
						url:item.fullurl,
						id:item.id
					}
					this.fileList.push(obj)
				})
				this.ruleForm.files = res.files.map((item)=>{return item.id}).join(',')
				delete this.ruleForm.createtime
				delete this.ruleForm.updatetime
			})
		},
		methods: {
			submitForm(status) {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						this.ruleForm.status = status
						this.ruleForm.files = this.fileList.map((item)=>{return item.id}).join(',')
						this.$post({
							url: '/api/user_resumption_report/edit',
							params: this.ruleForm
						}).then((res) => {
							this.$message.success('添加成功')
							this.$router.go(-1)
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			
			handleAvatarSuccess2(res, file) {
				let obj ={
					name:res.file_name,
					url:res.fullurl,
					id:res.id
				}
				this.fileList.push(obj)
			},
			handleChange2(file, fileList) {
				this.fileList = fileList			    
			},
			handleAvatarSuccess(res, file) {
				this.ruleForm.images = res.id
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 10;
			
				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG或PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			},
			backpage(){
				this.$router.go(-1)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.el-form-item {
			margin-right: 150px;
		}
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>